import React, { Component } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from '../components/section';
import {BsBuilding, BsFillPieChartFill, BsGeoAlt} from 'react-icons/bs';
import {WiDayCloudyGusts} from 'react-icons/wi';
import {IoMdOptions, IoIosWater, IoIosArrowRoundUp} from 'react-icons/io';
import {MdLocalOffer, MdPowerInput, MdStorage} from 'react-icons/md';
import {FaSolarPanel, FaChargingStation} from 'react-icons/fa';
import {GiWindTurbine} from 'react-icons/gi';
import {RiBattery2ChargeFill} from 'react-icons/ri';
import {WiCelsius} from 'react-icons/wi';
import styled from 'styled-components';
import Link from '../components/link';
import ContactForm from '../components/form';
import Button from '../components/button';



const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepNumber = styled.div`
  background-color: #C9DDFF;
  border-radius: ${props => `${props.size}px`};
  color: #242424;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${props => `${props.size}px`};
  width: ${props => `${props.size}px`};
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
`;

const Grid = ({items, itemRender, style}) => {
  return (
    <GridContainer style={style}>
      {items.map((item, index) => itemRender(item, index))}
    </GridContainer>
  )
}


const Steps = ({steps, style}) => {
  return (
    <Row style={style}>
      {steps.map((s, i) => {
        return (
          <Col style={{flex: 1, alignItems: 'center', marginLeft: 24}}>
            <StepNumber size={48}>
              {i+1}
            </StepNumber>
            <h3 style={{marginTop: 24, fontWeight: 500, color: '#C9DDFF', fontSize: 21}}>
              {s.title}
            </h3>
            <div style={{marginTop: 12, textAlign: 'center'}}>
              {s.text}
            </div>
          </Col>
        );
      })}
    </Row>
  )
}


class Platform extends Component {

  render() {
    return (
      <Layout {...this.props} transparentHeader>
        <SEO title="Platform" />
        <Section style={{position: 'relative', paddingLeft: 0, paddingRight: 0, marginTop: 0}}>
          <img src={require('../images/solar_panels_7.jpg')} style={{opacity: 0.3, position: 'absolute', minHeight: 650}}/>
          <div style={{paddingLeft: 48, paddingRight: 48, marginTop: 156}}>
            <h1>
              Transition your buildings to 100% renewable energy
            </h1>
            <p style={{fontSize: 21}}>
              Evaluate different clean energy solutions and scenarios for your buildings<br/> in minutes through our data-driven simulation platform.
            </p>
          </div>
          <Row style={{width: '100%', marginTop: 48}}>
            <div style={{flex: 1}}/>
            <img
              style={{height: 500, marginRight: 48, borderRadius: 4,  boxShadow: '0 2px 8px #121212'}}
              src={require('../images/eval_tool.png')}
            />
          </Row>

        </Section>
        <Section style={{marginTop: 256, minHeight: 400}}>
          <Col style={{alignItems: 'center'}}>
            <Col style={{alignItems: 'center'}}>
              <h1>
                Find the best clean energy solutions
              </h1>
              <p>
                Find the clean energy solutions that are best for your conditions among a vast library of potential solutions
              </p>
            </Col>
            <Grid
              style={{marginTop: 24}}
              itemRender={(item, index) => <Row style={{alignItems: 'center'}}><Col style={{display: 'flex', flex: 1, alignItems: 'center'}}><div style={{height: 64, width: 64}}>{item.icon}</div></Col><div style={{marginLeft: 16, flex: 3}}>{item.text}</div></Row>}
              items={[
                {icon: <img src={require('../images/icons/solar_panel.png')}/>, text: 'Solar panels'},
                {icon: <GiWindTurbine size={64} color='#c9ddff'/>, text: 'Wind turbines'},
                {icon: <img src={require('../images/icons/battery.png')}/>, text: 'Battery storage'},
                {icon: <Row style={{height: 54, width: 36, margin: '0 10px 0 10px', borderRadius: 4, alignItems: 'center'}}>
                  <div style={{height: '100%', width: 4, backgroundColor: '#C9DDFF', borderRadius: 4}}/>
                  <div style={{height: '100%', width: 4, backgroundColor: '#C9DDFF', borderRadius: 4, marginLeft: 4}}/>
                  <Col><WiCelsius size={30} color='#C9DDFF'/><IoIosArrowRoundUp color='#C9DDFF' size={30}/></Col>
                </Row>, text: 'Heat pumps'},
                {icon: <IoIosWater size={64} color='#c9ddff'/>, text: 'Electric boilers'},
                //{icon: <MdPowerInput size={64} color='#c9ddff'/>, text: 'DC microgrids'},
                {icon: <img src={require('../images/icons/grid2.png')}/>, text: 'DC microgrids'},
                {icon: <img src={require('../images/icons/ev_charger.png')}/>, text: 'EV charging'},
                {icon: <MdStorage size={64} color='#c9ddff'></MdStorage>, text: 'Heat storage'},
              ]}
            />

          </Col>
        </Section>
        <Section style={{minHeight: 400, marginTop: 256}}>
          <Row>
            <div style={{flex: 3, marginRight: 48}}>
              <img
                style={{borderRadius: 4,  boxShadow: '0 2px 8px #121212'}}
                src={require('../images/eval_tool_2.png')}
              />
            </div>
            <Col style={{flex: 2}}>
              <h1>
                Discover your solar potential
              </h1>
              <p>
                Our data-driven platform leverages state-of-the-art AI to automatically map the solar potential of your buildings.
              </p>
            </Col>
          </Row>
        </Section>
        <Section style={{marginTop: 256}}>
          <Row style={{justifyContent: 'center'}}>
            <h1>
               How it works
            </h1>
          </Row>
          <Steps
            style={{marginTop: 48}}
            steps={[
              {title: 'Get consumption', text: 'Get your energy consumption data automatically'},
              {title: 'Simulate', text: 'Simulate different energy technologies (powered by partner network)'},
              {title: 'Calculate KPIs', text: 'Calculate KPIs like return of investment and carbon emission reductions'},
              {title: 'Scale up', text: 'Scale up your clean energy upgrade calculations across your full portfolio and align with your goals'}
            ]}
          />
          <Col style={{alignItems: 'center', marginTop: 248}}>
            <h1 style={{textAlign: 'center'}}>
              Get started for free.
            </h1>
            <Button
              style={{marginTop: 24}}
              text='GET STARTED'
              to='/contact'
            />
          </Col>
          {/*<iframe src='http://localhost:3000/simulate' height={700} width='100%'
          style={{marginTop: 32}}
          frameBorder={0}/>*/}
        </Section>
      </Layout>
    );
  }

}

export default Platform;
